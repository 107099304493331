@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  @apply bg-gray-100;
}

html,
body,
#root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-primary-light;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-primary-default;
}

.parola-calendar .react-datepicker__day--selected,
.parola-calendar .react-datepicker__day--keyboard-selected {
  @apply bg-secondary-default hover:bg-secondary-dark;
}

.parola-calendar__wrapper .react-datepicker__close-icon::after {
  @apply bg-secondary-default;
}

h1, h2, h3, h4, h5, h6 {
  @apply my-2 font-bold antialiased;
}

h1 {
  @apply text-3xl;
}

h2 {
  @apply text-xl;
}

h3 {
  @apply text-lg;
}

ul {
  @apply list-disc list-inside mx-2;
}
